import React, { useState, useEffect } from 'react';
import LoadingSpinner from './components/LoadingSpinner';
import Home from './pages/home';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    // Função para carregar scripts assincronamente
    const loadScript = async (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    // Carregando os scripts
    const loadScripts = async () => {
      await loadScript('js/nice-select.min.js');
      await loadScript('js/jquery.nice-select.min.js');
    };

    // Função para inicializar após o carregamento dos scripts
    const initializeAfterScripts = async () => {
      // Aguarde o carregamento dos scripts
      await loadScripts();

      // nice select
      $(document).ready(function () {
        $('select').niceSelect();
      });

      // slick slider

      $(".slider_container").slick({
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 600,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: false,
        draggable: false,
        prevArrow: '<button class="slick-prev"> </button>',
        nextArrow: '<button class="slick-next"></button>',
        responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 420,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }
        ]
      });
    };

    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Inicialize após o carregamento dos scripts
    initializeAfterScripts();
    // Limpar o timeout ao desmontar o componente
    return () => clearTimeout(loadingTimeout);
  }, []);


  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Home />
        </div>
      )}
    </div>
  );
}


export default App;
