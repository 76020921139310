// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Cardapios from './Cardapios'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="home" element={<App />} />
        <Route path="*" element={<Cardapios />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
