import React from 'react';
import '../index.css'; // Arquivo CSS para estilizar o componente

function LoadingSpinner() {
  return (
    <div className="loading-spinner-container">
      {/* Aqui você pode adicionar a imagem de carregamento ou qualquer outro indicador */}
      <div className="loading-spinner"></div>
    </div>
  );
}

export default LoadingSpinner;
