import React, { useEffect } from "react";

function Home() {
    useEffect(() => {
        // Função para carregar scripts assincronamente
        const loadScript = async (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        };

        // Carregando os scripts
        const loadScripts = async () => {
            await loadScript('js/nice-select.min.js');
            await loadScript('js/jquery.nice-select.min.js');
        };

        // Função para inicializar após o carregamento dos scripts
        const initializeAfterScripts = async () => {
            // Aguarde o carregamento dos scripts
            await loadScripts();

            // nice select
            $(document).ready(function () {
                $('select').niceSelect();
            });

            // slick slider

            $(".slider_container").slick({
                autoplay: true,
                autoplaySpeed: 10000,
                speed: 600,
                slidesToShow: 4,
                slidesToScroll: 1,
                pauseOnHover: false,
                draggable: false,
                prevArrow: '<button class="slick-prev"> </button>',
                nextArrow: '<button class="slick-next"></button>',
                responsive: [{
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                }
                ]
            });
        };

        // Inicialize após o carregamento dos scripts
        initializeAfterScripts();
    }, []);

    const openNav = () => {
        document.getElementById("myNav").classList.toggle("menu_width");
        document.querySelector(".custom_menu-btn").classList.toggle("menu_btn-style");
    };
    return (
        <div>

            <div className="hero_area">
                <header className="header_section">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg custom_nav-container">
                            <a className="navbar-brand" href="index.html">
                                <span>
                                    PITÉUDASOGRA
                                </span>
                            </a>
                            <div className="" id="">
                                <div className="custom_menu-btn">
                                    <button onClick={openNav}>
                                        <img src="images/menu.png" alt="" />
                                    </button>
                                </div>
                                <div id="myNav" className="overlay">
                                    <div className="overlay-content">
                                        <a href="#home">Home</a>
                                        <a href="#sobre">Sobre</a>
                                        <a href="#avaliacoes">Avaliações</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>

                <section className="slider_section ">
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="detail-box">
                                    <h1>
                                        Venha conhecer o melhor restaurante de Angola
                                    </h1>
                                    <p>Uma viagem de sabores à África em Lisboa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slider_container">
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img1.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img2.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img3.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img4.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img1.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img2.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img3.png" alt="" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-box">
                                <img src="images/slider-img4.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="recipe_section layout_padding-top">
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>
                            Alguns dos nossos pratos
                        </h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img src="images/r1.png" className="box-img" alt="" />
                                </div>
                                <div className="detail-box">
                                    <h4>
                                        Calulu Carne Seca
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img src="images/r2.png" className="box-img" alt="" />
                                </div>
                                <div className="detail-box">
                                    <h4>
                                        Calulu de Peixe
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img src="images/r3.png" className="box-img" alt="" />
                                </div>
                                <div className="detail-box">
                                    <h4>
                                        Cabidela
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-box">
                        <a href="">
                            Encomendar agora
                        </a>
                    </div>
                </div>
            </section>

            <section className="app_section">
                <div className="container">
                    <div className="col-md-9 mx-auto">
                        <div className="row">
                            <div className="col-md-7 col-lg-8">
                                <div className="detail-box">
                                    <h2>
                                        <span> Conheça agora</span> <br />
                                        a nossa conta no Instagram
                                    </h2>
                                    <p>
                                        Acompanhe a nossa conta para ficar por dentro das promoções, pratos disponíveis e muito mais.
                                    </p>
                                    <div className="app_btn_box">
                                    </div>
                                    <a href="" className="download_btn">
                                        Abrir agora
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <div className="img-box">
                                    <img src="images/mobile.png" className="box-img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="about_section layout_padding">
                <div className="container">
                    <div className="col-md-11 col-lg-10 mx-auto">
                        <div className="heading_container heading_center">
                            <h2>
                                Sobre nós
                            </h2>
                        </div>
                        <div className="box">
                            <div className="col-md-7 mx-auto">
                                <div className="img-box">
                                    <img src="images/about-img.jpg" className="box-img" alt="" />
                                </div>
                            </div>
                            <div className="detail-box">
                                <p>O Pitéu da Sogra, fundado em 2023, é uma celebração da culinária angolana com uma abordagem contemporânea, buscamos encantar paladares exigentes, indo além de simplesmente alimentar.
                                    <br />
                                    Cada prato é uma homenagem à nossa rica tradição, preparado meticulosamente com ingredientes frescos. Desde muamba de galinha ao Mufete.
                                    <br />
                                    Desde 2023, conquistamos mais de 10 mil seguidores no Instagram, uma comunidade apaixonada que compartilha a apreciação pela nossa comida e o amor pela cultura angolana.
                                    <br />
                                    Se você é admirador de pratos angolanos tradicionais ou busca uma experiência única, o Pitéu da Sogra é o destino ideal, onde cada detalhe reflete a fusão de sabores e hospitalidade angolana</p>
                                <a href="">
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="client_section layout_padding">
                <div className="container">
                    <div className="col-md-11 col-lg-10 mx-auto">
                        <div className="heading_container heading_center">
                            <h2>
                                Testimonial
                            </h2>
                        </div>
                        <div id="customCarousel1" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="detail-box">
                                        <h4>
                                            Virginia
                                        </h4>
                                        <p>
                                            Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
                                        </p>
                                        <i className="fa fa-quote-left" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="detail-box">
                                        <h4>
                                            Virginia
                                        </h4>
                                        <p>
                                            Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
                                        </p>
                                        <i className="fa fa-quote-left" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="detail-box">
                                        <h4>
                                            Virginia
                                        </h4>
                                        <p>
                                            Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
                                        </p>
                                        <i className="fa fa-quote-left" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev d-none" href="#customCarousel1" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#customCarousel1" role="button" data-slide="next">
                                <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="footer_container">
                <section className="info_section ">
                    <div className="container">
                        <div className="contact_box">
                            <a href="">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div className="info_links">
                            <ul>
                                <li className="active">
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="about.html">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a className="" href="blog.html">
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a className="" href="testimonial.html">
                                        Testimonial
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="social_box">
                            <a href="">
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                            <a href="">
                                <i className="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </section>

                <footer className="footer_section">
                    <div className="container">
                        <p>
                            &copy; <span>2024</span> Todos direitos reservados pelo
                            <a href="https://html.design/"> Pitéu da Sogra</a><br />
                            made by: <a href="https://maygomes.com/">MayGomes</a>
                        </p>
                    </div>
                </footer>

            </div>
        </div>
    );
}

export default Home;
