import React, { lazy, Suspense, useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './App.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const LazyImage = lazy(() => import('./LazyImage'));

function Cardapios() {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Simulando pré-carregamento da imagem
    const img = new Image();
    img.src = 'Logotipo Pitéu da Sogra Compressed.png';

    img.onload = () => {
      // Define o estado para indicar que a imagem foi carregada
      setImageLoaded(true);
    };

    // Limpeza de efeito ao desmontar o componente (opcional)
    return () => {
      img.onload = null;
    };
  }, []);

  return (
    <div>
      <Suspense fallback={<div className={imageLoaded ? '' : 'loading-circle'}></div>}>
        {imageLoaded && (
          <div className="container col-md-12 col-lg-12 col-sm-12 text-center mt-5">
            <LazyImage />
            <br />
            <a className="mt-2 btn btn-danger btn-cardapio" target='_blank' rel="noreferrer" href='Menú Piteu da sogra.pdf'>Menú</a>
            <br />
            <a className="mt-2 btn btn-danger btn-cardapio" target='_blank' rel='noreferrer' href='Carta de vinhos Piteu da sogra.pdf'>Carta de Vinhos</a>
          </div>
        )}
      </Suspense>

      {imageLoaded && (
        <div className="col-lg-12 col-md-12 col-sm-12 text-center footer">
          <a href='https://www.instagram.com/piteu_da_sogra_/' rel='noreferrer' target='_blank' className='me-1 '>
            <img className='social-icon' src='3.png' alt="Instagram" />
          </a>
          <a href='https://api.whatsapp.com/send?phone=351963590830&text=Ol%C3%A1%2C%20%0AGostaria%20de%20fazer%20uma%20encomenda%20' rel='noreferrer' target='_blank' className='me-1 '>
            <img className='social-icon' src='2.png' alt="WhatsApp" />
          </a>
        </div>
      )}
    </div>
  );
}

export default Cardapios;
